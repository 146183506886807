// Sticky Plugin v1.0.4 for jQuery
// =============
// Author: Anthony Garand
// Improvements by German M. Bravo (Kronuz) and Ruud Kamphuis (ruudk)
// Improvements by Leonardo C. Daronco (daronco)
// Created: 02/14/2011
// Date: 07/20/2015
// Website: http://stickyjs.com/
// Description: Makes an element on the page stick on the screen as you scroll
//              It will only set the 'top' and 'position' of your element, you
//              might need to adjust the width in some cases.

(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery'], factory);
    } else if (typeof module === 'object' && module.exports) {
        // Node/CommonJS
        module.exports = factory(require('jquery'));
    } else {
        // Browser globals
        factory(jQuery);
    }
}(function ($) {
	$.fn.mainNav = function () {
			this.each(function () {
				var $this = $(this);
				var mainNavItems = $this.find('.MainNav__NavItem--level-1');
				mainNavItems.each(function (index, obj) {
					var subNav = $(obj).find('.MainNav__SubNav');
					if (subNav.length) {
						obj.subNav = $(obj).find('.MainNav__SubNav');
					}
				});

				var subNavs = $this.find('.MainNav__SubNav');
				var subNavBoxContainer = $('.MainNav__SubNavBoxContainer');
				var subNavBox = $('.MainNav__SubNavBox');
				subNavs.each(function (index, obj) {
					subNavBox.append(obj);
					// $(obj).css('display', 'block');
				});


				var lastNavIndex = -1;
				var lastSubNav = null;
				mainNavItems.each(function (index, obj) {
					$(obj).hover(function() {
						if (obj.subNav) {
							subNavBoxContainer.css('pointer-events', 'all');
							subNavBox.width(obj.subNav.outerWidth() - 2);
							subNavBox.height(obj.subNav.outerHeight());
							subNavBoxContainer.addClass('visible');


							$(obj.subNav).removeClass('animateInFromLeft');
							$(obj.subNav).removeClass('animateInFromRight');
							$(obj.subNav).removeClass('animateOutToLeft');
							$(obj.subNav).removeClass('animateOutToRight');

							$(lastSubNav).removeClass('animateOutToRight');
							$(lastSubNav).removeClass('animateInFromRight');
							$(lastSubNav).removeClass('animateOutToLeft');
							$(lastSubNav).removeClass('animateInFromLeft');

							if (index > lastNavIndex) {
								$(obj.subNav).addClass('animateInFromRight');
								$(lastSubNav).addClass('animateOutToLeft');
							}
							else if (index === lastNavIndex) {
								$(obj.subNav).addClass('animateInFromLeft');
							}
							else {
								$(obj.subNav).addClass('animateInFromLeft');
								$(lastSubNav).addClass('animateOutToRight');
							}

							lastNavIndex = index;
							lastSubNav = obj.subNav;
						}
						else {
							// NO SUBNAV
							subNavBox.removeClass('MainNav__SubNavBox--visible');
							$(lastSubNav).removeClass('animateOutToRight');
							$(lastSubNav).removeClass('animateInFromRight');
							$(lastSubNav).removeClass('animateOutToLeft');
							$(lastSubNav).removeClass('animateInFromLeft');
							lastSubNav = null;
							subNavBox.width(0);
							subNavBox.height(0);
						}
						subNavBox.css('left', $(obj).position().left + $(obj).width() * 0.5 - obj.subNav.outerWidth() * 0.5 + 'px');
						setTimeout(function() {
							subNavBox.removeClass('noDropDownTransition');
						}, 100);
					}, function () {
						setTimeout(function() {
							subNavBox.addClass('noDropDownTransition');
						}, 100);
						subNavBoxContainer.removeClass('visible');
					});
				});

				subNavBox.hover(function() {
					setTimeout(function() {
						subNavBox.removeClass('noDropDownTransition');
					}, 100);
					if (lastSubNav) {
						subNavBoxContainer.addClass('visible');
					}
				}, function() {
					subNavBoxContainer.removeClass('visible');
					subNavBoxContainer.css('pointer-events', 'none');
					setTimeout(function() {
						subNavBox.addClass('noDropDownTransition');
					}, 300);
				})



				var searchBtn = $('.SecondaryNav__NavItem--search');
				var searchBar = $('.Page__Search');
				var searchCloseBtn = $('.Search__CloseBtn');
				var searchInput = searchBar.find('.Search__Input');

				function showSearch() {
					searchBar.addClass('Page__Search--visible');
					setTimeout(function() { searchInput.focus() }, 500);
				}
				function hideSearch() {
					searchBar.removeClass('Page__Search--visible');
				}

				var dimmer = $('.Dimmer');
				function showDarkOverlay() {
					dimmer.addClass('visible');
					dimmer.removeClass('out');
				}
				function hideDarkOverlay() {
					dimmer.removeClass('visible');
					dimmer.addClass('out');
				}

				dimmer.click(function() {
					hideDarkOverlay();
					hideSearch();
					hideMobileNav();
				});


				searchBtn.click(function() {
					showSearch();
					showDarkOverlay()
				});

				searchCloseBtn.click(function() {
					hideSearch();
					hideDarkOverlay();
				});



				var mobileNav = $('.Page__MobileNav');

				function showMobileNav() {
					mobileNav.addClass('Page__MobileNav--visible');
					showDarkOverlay();
				}

				function hideMobileNav() {
					mobileNav.removeClass('Page__MobileNav--visible');
					hideDarkOverlay();
				}

				$('.SecondaryNav__NavItem--menu').click(function() {
					showMobileNav();
				});

				$('.MobileNav__CloseBtn').click(function() {
					hideMobileNav();
				});
			});
		};
}));

