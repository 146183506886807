// Sticky Plugin v1.0.4 for jQuery
// =============
// Author: Anthony Garand
// Improvements by German M. Bravo (Kronuz) and Ruud Kamphuis (ruudk)
// Improvements by Leonardo C. Daronco (daronco)
// Created: 02/14/2011
// Date: 07/20/2015
// Website: http://stickyjs.com/
// Description: Makes an element on the page stick on the screen as you scroll
//              It will only set the 'top' and 'position' of your element, you
//              might need to adjust the width in some cases.

(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery'], factory);
    } else if (typeof module === 'object' && module.exports) {
        // Node/CommonJS
        module.exports = factory(require('jquery'));
    } else {
        // Browser globals
        factory(jQuery);
    }
}(function ($) {
	$.fn.accordion = function () {
		this.each(function () {
			var $this = $(this);
			var autoCollapse = $this.data('autoCollapse') ? $this.data('autoCollapse') : false;
			var hash = window.location.hash.substr(1);
			var hashItem = null;
			var hashItemContent = null;

			if (autoCollapse && hash) {
				hashItem = $($this.find('#' + hash));
				hashItem.addClass('active');
				hashItemContent = $(hashItem.find('.js-Accordion__Content'));
			}

			if (!hashItem) {
				$this.find('.js-Accordion__Content' + (autoCollapse ? ':not(:first)' : '')).slideUp(0);
				if (!$this.hasClass('MobileNav__List') && autoCollapse) {
					$this.find('.js-Accordion__Item' + (autoCollapse ? ':first' : '')).addClass('active');
				}
			}
			else {
				$this.find('.js-Accordion__Content').not(hashItemContent).slideUp(0);
				document.querySelector('#' + hash).scrollIntoView({ behavior: 'smooth' });
			}

		    $this.find('.js-Accordion__Button').click(function(j) {
		        var dropDown = $(this).closest('.js-Accordion__Item').find('.js-Accordion__Content');

		        var item = $(this).closest('.js-Accordion__Item');

		        if (!autoCollapse) {
		        	$this.find('.js-Accordion__Content').not(dropDown).slideUp();
		        	$this.find('.js-Accordion__Item').not(item).removeClass('active');
		        }

		        item.toggleClass('active');

		        dropDown.stop(false, true).slideToggle();

		        j.preventDefault();
		    });

		});
	};
}));

