"use strict";

// Polyfill (ES6 (original) version available under YT Craft Skeleton)
function _instanceof(left, right) { if (right != null && typeof Symbol !== "undefined" && right[Symbol.hasInstance]) { return !!right[Symbol.hasInstance](left); } else { return left instanceof right; } }
function _classCallCheck(instance, Constructor) { if (!_instanceof(instance, Constructor)) { throw new TypeError("Cannot call a class as a function"); } }
function _defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } }
function _createClass(Constructor, protoProps, staticProps) { if (protoProps) _defineProperties(Constructor.prototype, protoProps); if (staticProps) _defineProperties(Constructor, staticProps); return Constructor; }

// Polyfill for IE 11
if ("NodeList" in window && !NodeList.prototype.forEach) {NodeList.prototype.forEach = function (callback, thisArg) { thisArg = thisArg || window;for (var i = 0; i < this.length; i++) {callback.call(thisArg, this[i], i, this) } } }

// Polyfill for CustomEvent()
(function () {
  if ( typeof window.CustomEvent === "function" ) return false;
  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
   }

  window.CustomEvent = CustomEvent;
})();

/**
 * Utilities
 *
 * @object
 * @constructor
 * @public
 */
var Utils = {
  /**
   * Cookie API
   */
  cookie: {
    /**
     * Get cookie by name
     * @param {string} name The cookie name
     * @returns {string} The cookie value
     */
    get: function get(name) {
      var cookieName = encodeURIComponent(name) + '=';
      var cookieStart = document.cookie.indexOf(cookieName);
      var cookieValue = null;
      var cookieEnd;

      if (cookieStart > -1) {
        cookieEnd = document.cookie.indexOf(';', cookieStart);

        if (cookieEnd == -1) {
          cookieEnd = document.cookie.length;
        }

        cookieValue = decodeURIComponent(document.cookie.substring(cookieStart + cookieName.length, cookieEnd));
      }

      return cookieValue;
    },

    /**
     * Set cookie by name
     * @param {string} name The cookie name
     * @param {string} value The cookie value
     * @param {number} expires The cookie expiration date in days
     * @param {string} path The cookie path
     * @param {string} domain The cookie domain
     * @param {boolean} secure The cookie secure flag
     */
    set: function set(name, value, expires, path, domain, secure) {
      var cookieText = encodeURIComponent(name) + '=' + encodeURIComponent(value);

      if (expires) {
        var date = new Date();
        date.setTime(date.getTime() + expires * 24 * 60 * 60 * 1000);
        cookieText += '; expires=' + date.toUTCString();
      }

      if (path) {
        cookieText += '; path=' + path;
      }

      if (domain) {
        cookieText += '; domain=' + domain;
      }

      if (secure) {
        cookieText += '; secure';
      }

      document.cookie = cookieText;
    },

    /**
     * Unset a defined cookie
     * @param {string} name The cookie name
     * @param {string} path The cookie path
     * @param {string} domain The cookie domain
     * @param {boolean} secure The cookie secure flag
     */
    unset: function unset(name, path, domain, secure) {
      this.set(name, '', new Date(0), path, domain, secure);
    }
  }
};

/**
 * Cookie Consent
 *
 * Usage via global event listener:
 * window.addEventListener('app.cookieConsent.onChange', function(event) {
 *   console.log('The cookie consent has been updated.', app.cookies.cookieConsent.types);
 * });
 *
 * Usage via statement (example: Does the user allow external media?):
 * if (app.cookies.cookieConsent.types.external) { … }
 *
 * Open the cookie consent layer via a link with the hashtag "#cookie-consent":
 * <a href="#cookie-consent">Manage your cookie settings</a>
 *
 * @class
 * @constructor
 * @public
 */

var cookieConsent = function () {
  /**
   * Constructor
   * @returns {class}
   */
  function cookieConsent() {
    _classCallCheck(this, cookieConsent);

    !app.cookies && console.error('Application: window.app.cookies object required.');
    this.cookieConsent = document.getElementById('cookie-consent--js');
    this.saveButtons = this.cookieConsent.querySelectorAll('#cookie-consent-save, a');
    this.acceptButton = document.getElementById('cookie-consent-accept');
    this.openTrigger = document.querySelectorAll('[href="#cookie-consent"]');

    if (!this.cookieConsent || !app.cookies.cookieConsent) {
      return;
    }

    // Engage engines
    this.init();
  }
  /**
   * Initialize the component
   * @returns {void}
   */


  _createClass(cookieConsent, [{
    key: "init",
    value: function init() {
      if (Utils.cookie.get(app.cookies.cookieConsent.name)) {
        app.cookies.cookieConsent.types = JSON.parse(Utils.cookie.get(app.cookies.cookieConsent.name));
        this.fireCustomEvent();
      } else {
        app.cookies.cookieConsent.types = {};
        this.openCookieConsent();
      }

      this.addEventListeners();

      /**
       * Detect Aklamio (aid) and Adcell (bid) Parameter
       **/
      const urlSearchParams = new URLSearchParams(window.location.search);
		console.log('aid: ', urlSearchParams.get('aid'), 'bid: ', urlSearchParams.get('bid'));
      if (urlSearchParams.has('aid') === true) {
      	// window.aid = urlSearchParams.get('aid');
      	Utils.cookie.set(app.cookies.aid.name, urlSearchParams.get('aid'), app.cookies.aid.expires, app.cookies.aid.path);
      	if (Utils.cookie.get(app.cookies.bid.name)) {
      		Utils.cookie.unset(app.cookies.bid.name, app.cookies.bid.path);
      	}
	  } else if (Utils.cookie.get(app.cookies.aid.name)) {
      	window.aid = Utils.cookie.get(app.cookies.aid.name);
      } else {
      	window.aid = false;
      }

      if (urlSearchParams.has('bid') === true) {
      	// window.bid = urlSearchParams.get('bid');
      	Utils.cookie.set(app.cookies.bid.name, urlSearchParams.get('bid'), app.cookies.bid.expires, app.cookies.bid.path);
      	if (Utils.cookie.get(app.cookies.aid.name)) {
			Utils.cookie.unset(app.cookies.aid.name, app.cookies.aid.path);
		}
      } else if (Utils.cookie.get(app.cookies.bid.name)) {
		  window.bid = Utils.cookie.get(app.cookies.bid.name);
	  } else {
      	window.bid = false;
      }

      return this;
    }
  }, {
    key: "addEventListeners",
    value: function addEventListeners() {
      var _this = this;

      this.saveButtons.forEach(function (saveButton) {
        saveButton.addEventListener('click', _this.onSaveButtonsClick.bind(_this));
      });
      this.acceptButton.addEventListener('click', this.onAcceptButtonClick.bind(this));
      this.openTrigger.forEach(function (trigger) {
        trigger.addEventListener('click', _this.onOpenTrigger.bind(_this));
      });
    }
  }, {
    key: "onSaveButtonsClick",
    value: function onSaveButtonsClick() {
      this.setCookieConsent();
      this.closeCookieConsent();
    }
  }, {
    key: "onAcceptButtonClick",
    value: function onAcceptButtonClick() {
      this.setCookieConsent('all' /* Select all checkboxes */);
      this.closeCookieConsent();
    }
  }, {
    key: "onOpenTrigger",
    value: function onOpenTrigger(event) {
      event.preventDefault();
      this.openCookieConsent();
    }
  }, {
    key: "openCookieConsent",
    value: function openCookieConsent() {
      this.cookieConsent.classList.add('is-active');
      document.body.classList.add('overlay--prevent-bodyscrolling');
      this.updateCheckboxes('update');
    }
  }, {
    key: "closeCookieConsent",
    value: function closeCookieConsent() {
      this.cookieConsent.classList.remove('is-active');
      document.body.classList.remove('overlay--prevent-bodyscrolling');

      if (window.location.hash == '#cookie-consent') {
        window.history.pushState('', document.title, window.location.pathname);
      }
    }
  }, {
    key: "fireCustomEvent",
    value: function fireCustomEvent() {
      window.dispatchEvent(new CustomEvent('app.cookieConsent.onChange'));
    }
  }, {
    key: "setCookieConsent",
    value: function setCookieConsent(selectCheckboxes) {
      this.updateCheckboxes(selectCheckboxes);
      this.fireCustomEvent();

      // Set cookie
      Utils.cookie.set(app.cookies.cookieConsent.name, JSON.stringify(app.cookies.cookieConsent.types), app.cookies.cookieConsent.expires, app.cookies.cookieConsent.path);
    }
  }, {
    key: "updateCheckboxes",
    value: function updateCheckboxes(selectCheckboxes) {
      var form = this.cookieConsent;

      for (var i = 0; i < form.elements.length; i++) {
        if (form.elements[i].type == 'checkbox') {
          if (selectCheckboxes == 'all') {
            form.elements[i].checked = true;
          }

          // Populate checkboxes
          if (selectCheckboxes == 'update' && !form.elements[i].disabled) {
            form.elements[i].checked = app.cookies.cookieConsent.types[form.elements[i].value];
          }

          // Store values
          app.cookies.cookieConsent.types[form.elements[i].value] = form.elements[i].checked;
        }
      }
    }
  }]);

  return cookieConsent;
}();

module.exports = cookieConsent;
