(function(factory) {
	if (typeof define === "function" && define.amd) {
		// AMD. Register as an anonymous module.
		define(["jquery"], factory);
	} else if (typeof module === "object" && module.exports) {
		// Node/CommonJS
		module.exports = factory(require("jquery"));
	} else {
		// Browser globals
		factory(jQuery);
	}
})(function($) {
	$.fn.rateForm = function() {
		this.each(function() {
			var form = this;
			var $this = $(this);
			var submitBtn = $this.find(".js-RateForm__Submit");
			var submitBtnText = submitBtn.first().text();

			var postalCodeField = $this.find(".js-RateForm__PostalCodeField");
			var postalCodeInput = postalCodeField.find(".js-RateForm__Input");
			var postalCodeStatus = postalCodeField.find(".js-RateForm__Status");

			var citySelectField = $this.find(".js-RateForm__CityField");
			var citySelect = citySelectField.find(".js-RateForm__Input");
			var citySelectStatus = citySelectField.find(".js-RateForm__Status");

			var streetSelectField = $this.find(".js-RateForm__StreetField");
			var streetSelect = streetSelectField.find(".js-RateForm__Input");
			var streetSelectStatus = streetSelectField.find(".js-RateForm__Status");

			var houseNumberField = $this.find(".js-RateForm__HouseNumberField");
			var houseNumberInput = houseNumberField.find(".js-RateForm__Input");
			var houseNumberStatus = postalCodeField.find(".js-RateForm__Status");

			var isFormValid = false;
			var kwhInput = $this.find(".js-RateForm__KwhInput");
			var kwhSelectBtns = $this.find(".js-RateForm__KwhBtn");

			kwhSelectBtns.click(function() {
				var btn = $(this);
				$(kwhInput).val(btn.data("value"));
				kwhSelectBtns.removeClass("selected");
				btn.addClass("selected");
			});

			kwhInput.keydown(function() {
				kwhSelectBtns.removeClass("selected");
			});

			function resetAddress() {
				citySelect.html("");
				citySelectField.hide();
				streetSelect.html("");
				streetSelectField.hide();
				houseNumberField.hide();
				removeAllErrors();
				postalCodeField.removeClass("error");
			}

			postalCodeInput.keydown(resetAddress);
			postalCodeInput.change(resetAddress);

			citySelect.change(function() {
				streetSelect.html("");
				streetSelectField.hide();
				removeAllErrors();
			});

			var isFetchingResults = false;

			function submit(e) {
				if (isFetchingResults) {
					return;
				}
				if (postalCodeInput.first().val() === "") {
					postalCodeStatus.html("Pflichtfeld");
					postalCodeField.addClass("error");
					e.preventDefault();
					return;
				}
				if (!isFormValid) {
					var values = {};
					$this.serializeArray().forEach(function(obj) {
						values[obj.name] = obj.value;
					});

					submitBtn.addClass("disabled");
					submitBtn.text(submitBtn.data("loading"));
					isFetchingResults = true;
					var ajax = $.get(
						"/actions/businessLogic/getRates",
						values,
						function(data) {
							if (data.vnbID) {
								$this.append(
									'<input type="hidden" name="vnbID" value="' +
										data.vnbID +
										'">'
								);
							}
							data.rates.forEach(function(rate) {
								Object.keys(rate).forEach(function(key) {
									$this.append(
										'<input type="hidden" name="rates[' +
											rate.rateName +
											"][" +
											key +
											']" value="' +
											rate[key] +
											'">'
									);
								});
							});

							Object.keys(data.request).forEach(function(key) {
								$this.append(
									'<input type="hidden" name="request[' +
										key +
										']" value="' +
										data.request[key] +
										'">'
								);
							});

							var dataField = $('<input type="hidden" name="data">');
							dataField.val(JSON.stringify(data));
							$this.append(dataField);

							isFormValid = true;
							$this.submit();
						},
						"json"
					)
						.fail(function(data) {
							submitBtn.removeClass("disabled");
							submitBtn.text(submitBtnText);
							isFetchingResults = false;
							if (
								(ajax.getResponseHeader("content-type") || "").indexOf("json") >
									-1 &&
								data.responseJSON
							) {
								data.responseJSON.forEach(handleError);
							}
						})
						.always(function() {});

					if (e) {
						e.preventDefault();
					}
				}
			}

			$this.submit(submit);

			function removeAllErrors() {
				postalCodeField.removeClass("error");
				citySelectField.removeClass("error");
				streetSelectField.removeClass("error");
			}

			function handleError(obj) {
				switch (obj.parameterName) {
					case "postalCode":
						if (obj.solutionValues.city) {
							citySelect.html("");
							obj.solutionValues.city.forEach(function(city) {
								citySelect.append("<option>" + city + "</option>");
							});
							citySelectField.show();
							if (obj.solutionValues.city.length == 1) {
								submit();
							} else {
								citySelect.focus();
							}
						}
						break;
					case "city":
						if (obj.solutionValues.street) {
							streetSelect.html("");
							obj.solutionValues.street.forEach(function(street) {
								streetSelect.append("<option>" + street + "</option>");
							});
							streetSelectField.show();
							if (obj.solutionValues.street.length == 1) {
								submit();
							} else {
								streetSelect.focus();
							}
						}
						break;
					case "street":
						houseNumberInput.val("");
						houseNumberField.show();
						houseNumberInput.focus();
						break;
					case "address":
						if (obj.solutionValues.length == 0) {
							// postalCodeStatus.html(obj.solution);
							postalCodeStatus.html("PLZ ungültig");
							postalCodeField.addClass("error");
						}
						break;
				}
			}
		});
	};
});
